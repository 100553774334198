<template>
	<div class="searchFrom">
		<el-form :inline="true" :model="searchForm" class="demo-form-inline">
			<el-form-item label="搜索内容">
				<el-input class="w300" v-model="searchForm.keyWords" placeholder="请输入姓名、联系方式、详细地址" clearable></el-input>
			</el-form-item>
			<el-form-item :label="timeLable" v-if="timeType === 'range'">
				<el-date-picker
					v-model="timeRange"
					type="daterange"
					value-format="yyyy-MM-dd"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					clearable
					class="w300"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item :label="timeLable" v-if="timeType === 'time'">
				<el-date-picker v-model="stopDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" clearable> </el-date-picker>
			</el-form-item>
			<el-form-item label="申请月份" v-if="timeType === 'month'">
				<el-date-picker class="w300" v-model="createTime" type="month" value-format="yyyy-MM" placeholder="请选择月份"> </el-date-picker>
			</el-form-item>
			<el-form-item label="签约状态" v-if="showSign">
				<el-select class="w300" v-model="signStatus" placeholder="请选择签约状态" clearable>
					<el-option v-for="item in SIGNOPTIONS" :label="item.label" :value="item.value" :key="item.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="建设状态" v-if="showState">
				<el-select class="w300" v-model="status" placeholder="请选择建设状态" clearable>
					<el-option v-for="item in BUILDSTATE" :label="item.label" :value="item.value" :key="item.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="服务状态" v-if="showService">
				<el-select class="w300" v-model="serviceStatus" placeholder="请选择服务状态" clearable>
					<el-option v-for="item in SERVICEOPTIONS" :label="item.label" :value="item.value" :key="item.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="所属组织">
				<el-cascader
					clearable
					placeholder="请选择所属组织"
					v-model="streetArr"
					:props="defaultProps"
					class="w300"
					ref="supervisedUnitCode"
					:show-all-levels="false"
					filterable
					:options="streetList"></el-cascader>
			</el-form-item>
			<el-form-item label="服务机构" v-if="showOrgan">
				<el-select class="w300" v-model="sysId" placeholder="请选择服务机构" filterable clearable>
					<el-option v-for="item in organList" :label="item.serviceName" :value="item.id" :key="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="老人能力">
				<el-select class="w300" v-model="searchForm.elderlyAbility" placeholder="请选择老人能力" clearable>
					<el-option v-for="item in ELDERLYABILITY" :label="item.label" :value="item.value" :key="item.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="老人类型">
				<el-select class="w300" v-model="searchForm.elderlyType" placeholder="请选择老人类型" clearable>
					<el-option v-for="item in ELDERLYTYPE" :label="item.label" :value="item.value" :key="item.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit">搜索</el-button>
			</el-form-item>
			<el-form-item style="float: right">
				<el-button type="primary" plain @click="doExport">导出</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { BUILDSTATE, ELDERLYABILITY, ELDERLYTYPE, SIGNOPTIONS, SERVICEOPTIONS } from '@/data/dataDictionary'
import { getCurrentMonth } from '@/utils/date'
import { streetList, serviceNameList } from '@/api/orderManage'
import { exportAll, superviseExportAll, stopExportAll, signExportAll } from '@/api/bedManage'
import { downloadStream } from '@/utils/index'
export default {
	// 搜索表单
	name: 'searchFrom',
	props: {
		exportType: {
			type: String,
			default: 'sign', // sign 签约 | build 建设 | supervise 监管 | stop 停发
		},
		searchType: {
			type: Number,
			default: 1,
		},
		timeLable: {
			type: String,
			default: '申请时间',
		},
		timeType: {
			type: String,
			default: null, //  time 时间 | month 月份 | range 范围
		},
		showSign: {
			type: Boolean,
			default: false,
		},
		showState: {
			type: Boolean,
			default: false,
		},
		showOrgan: {
			type: Boolean,
			default: false,
		},
		showService: {
			type: Boolean,
			default: true,
		},
	},
	mounted() {
		if (this.timeType === 'month') {
			this.createTime = getCurrentMonth()
		}
		this.getOrgan()
		this.getStreetList()
		setInterval(function() {// 解决级联选择器不点击圆点选中元素问题
			document.querySelectorAll(".el-cascader-node__label").forEach(el => {
				el.onclick = function() {
					if (this.previousElementSibling) this.previousElementSibling.click();
				};
			});
		}, 1000);
	},
	data() {
		return {
			BUILDSTATE,
			ELDERLYABILITY,
			ELDERLYTYPE,
			SIGNOPTIONS,
			SERVICEOPTIONS,
			streetList: [], // 组织列表
			organList: [],
			stopDate: '', // 停发时间
			timeRange: '', // 时间范围
			createTime: '', // 时间月份
			sysId: '', // 街道的id(服务机构使用)
			status: null, // 床位状态
			signStatus: null, // 签约状态
			serviceStatus: null, //服务状态
			streetArr: [],
			searchForm: {
				keyWords: '',
				streetId: null,
				elderlyAbility: null,
				elderlyType: null,
			},
			defaultProps: {
				value: 'streetId',
				label: 'streetName',
				children: 'streetNameListResultList',
				checkStrictly: true
			},
		}
	},
	methods: {
		getParams() {
			var searchParams = {
				...this.searchForm,
			}
			if (this.timeType === 'range') {
				searchParams = {
					...this.searchForm,
					startTime: this.timeRange ? this.timeRange[0] : '',
					endTime: this.timeRange ? this.timeRange[1] : '',
				}
			}
			if (this.timeType === 'month') {
				searchParams = {
					...this.searchForm,
					createTime: this.createTime,
				}
			}
			if (this.timeType === 'time') {
				searchParams = {
					...this.searchForm,
					stopDate: this.stopDate,
				}
			}
			if (this.showState) {
				searchParams = { ...searchParams, status: this.status }
			}
			if (this.showOrgan) {
				searchParams = { ...searchParams, sysId: this.sysId }
			}
			if (this.showSign) {
				searchParams = { ...searchParams, signStatus: this.signStatus }
			}
			if (this.showService) {
				searchParams = { ...searchParams, serviceStatus: this.serviceStatus }
			}
			if(this.streetArr.length) searchParams.streetId = this.streetArr[this.streetArr.length - 1]
			return searchParams
		},
		onSubmit() {
			let searchParams = this.getParams()
			console.log(searchParams, 'searchParams')
			this.$emit('searchSubmit', searchParams)
		},
		// 获取机构列表
		getOrgan() {
			serviceNameList().then((res) => {
				this.organList = res.data
			})
		},
		// 获取组织列表
		getStreetList() {
			streetList().then((res) => {
				this.streetList = res.data
			})
		},
		async doExport() {
			const params = this.getParams()
			const exportFun =
				this.exportType === 'build'
					? exportAll
					: this.exportType === 'supervise'
					? superviseExportAll
					: this.exportType === 'sign'
					? signExportAll
					: stopExportAll
			const res = await exportFun(params)
			const str =
				this.exportType === 'build'
					? '床位建设列表.xls'
					: this.exportType === 'supervise'
					? '床位监管列表.xls'
					: this.exportType === 'sign'
					? '床位签约列表.xls'
					: '停发列表.xls'
			downloadStream(res, str)
		},
	},
}
</script>

<style lang="scss" scoped>
.searchFrom {
	.w300 {
		width: 1.5625rem /* 100/192 */;
	}
}
</style>
